import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, prefetchPathname, navigate } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getValidationSchemaPump } from "../validations"
import Autocomplete from "@material-ui/lab/Autocomplete"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import {
  getTowns,
  basicPumpCalculation as pumpCalculation,
  finishBasicPumpCalculation,
  getFlowsList,
  addSizingSave,
  addAudit,
  finishSizingResult
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import uniqBy from "lodash/uniqBy"
import sortBy from "lodash/sortBy"
import domtoimage from "dom-to-image"
import roundTo from "round-to"
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import loadable from '@loadable/component'

const BasicPdf = loadable.lib(() => import('./basicPdf'))

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
    <circle
      r={size / 5}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
)

const MyResponsiveLine = ({ data, loaded }) => (
  <ResponsiveLine
    isInteractive={false}
    curve="monotoneX"
    data={data}
    margin={{ top: 20, right: 100, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
    animate={false}
    // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    // yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 45,
      legend: 'Time',
      legendOffset: 40,
      legendPosition: 'middle'
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Litres per hour',
      legendPosition: 'middle',
      legendOffset: -50
    }}
    colors={['#00aeef']}
    enablePointLabel={false}
    // pointLabel={(d)=>`${d.y/1000}`}
    enablePoints={false}
    // pointSymbol={CustomSymbol}
    pointSize={10}
    // pointColor={{ theme: 'background' }}
    pointBorderWidth={1}
    pointBorderColor={{
      from: 'color',
      modifiers: [['darker', 0.3]],
    }}
    // pointLabelYOffset={-12}
    useMesh={true}
    layers={[
      'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
      () => {
        loaded()
        return null
      }
    ]}
  // legends={[
  //   {
  //     anchor: 'bottom-right',
  //     direction: 'column',
  //     justify: false,
  //     translateX: 100,
  //     translateY: 0,
  //     itemsSpacing: 0,
  //     itemDirection: 'left-to-right',
  //     itemWidth: 80,
  //     itemHeight: 20,
  //     itemOpacity: 0.75,
  //     symbolSize: 12,
  //     symbolShape: 'circle',
  //     symbolBorderColor: 'rgba(0, 0, 0, .5)',
  //     effects: [
  //       {
  //         on: 'hover',
  //         style: {
  //           itemBackground: 'rgba(0, 0, 0, .03)',
  //           itemOpacity: 1
  //         }
  //       }
  //     ]
  //   }
  // ]}
  />
)

const MyResponsiveBar = ({ data, loaded }) => (
  <ResponsiveBar
    isInteractive={false}
    data={data}
    keys={['flow']}
    indexBy="month"
    animate={false}
    margin={{ top: 20, right: 100, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    // colors={{ scheme: 'category10' }}
    colors={['#00aeef']}


    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 45,
      legend: 'Month',
      legendPosition: 'middle',
      legendOffset: 36
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Litres per Day',
      legendPosition: 'middle',
      legendOffset: -50
    }}
    // labelSkipWidth={12}
    // labelSkipHeight={12}
    // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 4 ] ] }}
    enableLabel={false}
    // label={d => `${roundTo(d.value/1000, 1)}`}
    layers={[
      'grid', 'axes', 'bars', 'markers', 'legends', 'annotations',
      () => {
        loaded()
        return null
      }
    ]}

  // labelTextColor={'white'}
  // pointSize={16}
  // legends={[
  //     {
  //         dataFrom: 'keys',
  //         anchor: 'bottom-right',
  //         direction: 'column',
  //         justify: false,
  //         translateX: 120,
  //         translateY: 0,
  //         itemsSpacing: 2,
  //         itemWidth: 100,
  //         itemHeight: 20,
  //         itemDirection: 'left-to-right',
  //         itemOpacity: 0.85,
  //         symbolSize: 20,
  //         effects: [
  //             {
  //                 on: 'hover',
  //                 style: {
  //                     itemOpacity: 1
  //                 }
  //             }
  //         ]
  //     }
  // ]}
  />
)

function ResultTable({ title, result, selectedResult }) {
  const [filteredResult, setFilteredResult] = useState(result)
  const [showMorePumps, setShowMorePumps] = useState(false)
  const [sortOnRand] = useState(false)


  useEffect(() => {
    const extra = showMorePumps ? 100 : 3
    // sortAndFilterResults(extra, sortOnRand)

    let theBestPumpIndex = 0
    if (result.length > 0) {
      theBestPumpIndex = result.findIndex(a => a.pumpWorks)
    }
    let newResult = result.slice(
      theBestPumpIndex !== 0 ? theBestPumpIndex - 1 : 0,
      theBestPumpIndex + extra
    )
    // if (rand) {
    //   newResult = orderBy(
    //     newResult,
    //     a =>
    //       getCost({
    //         roles: [], //authUser.roles.role,
    //         panel: a.panelCosts.cost,
    //         pump: a.pumpCosts.cost,
    //         numPanels: a.panels,
    //         panelMarkup: authUser.settings.markupPanels,
    //         pumpMarkup: authUser.settings.markupPumps,
    //       }) / a.averageflowPerDay,
    //     "asc"
    //   )
    // }
    setFilteredResult(newResult)
  }, [showMorePumps, sortOnRand])



  return (
    <div style={{ overflowX: "auto" }}>
      <>
        <Table aria-label={title + " Pumps"}>
          <TableHead>
            <TableRow>
              <TableCell>{title} Pumps</TableCell>
              <TableCell align="right">Ave liter per day</TableCell>
              <TableCell align="right">Yield enough water</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResult.length === 0 &&
              <TableRow>
                <TableCell
                  colSpan="3"
                  component="th"
                  scope="row"
                  style={{
                    backgroundColor: "red",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  There are no results for these inputs. Try different inputs or contact
                  us at support@cedarsolar.com for a custom sizing on large projects.
                </TableCell>
              </TableRow>}
            {filteredResult.map(row => (
              <TableRow
                onClick={() => {
                  selectedResult(row)
                }}
                key={row.id}
                style={
                  row.pumpWorks
                    ? {
                      backgroundColor: "green",
                      cursor: "pointer",
                      color: "white",
                    }
                    : {
                      backgroundColor: "red",
                      cursor: "pointer",
                      color: "white",
                    }
                }
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: "white" }}
                >
                  {row.name}
                </TableCell>
                <TableCell align="right" style={{ color: "white" }}>
                  {row.averageflowPerDay}
                </TableCell>
                <TableCell align="right" style={{ color: "white" }}>
                  {row.pumpWorks ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {filteredResult.length > 0 && (
          <FormControlLabel
            style={{ marginLeft: "2px" }}
            control={
              <Checkbox
                checked={showMorePumps}
                onChange={e => setShowMorePumps(e.target.checked)}
                value="showMorePumps"
              />
            }
            label={"Show More " + title + " Pumps"}
          />)}
      </>
    </div>
  )
}

function ResultDialog({ show, result, onClose }) {
  const [open, setOpen] = React.useState(show)
  const [showResultDisplay, setShowResultDisplay] = useState(null)
  const dispatch = useDispatch()

  const { sizingSaves, lastBasicPumpInput } = useSelector(state => state.db)

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <React.Fragment>
      {showResultDisplay && (
        <ResultDisplayDialog
          inputs={lastBasicPumpInput.pumpValues}
          result={showResultDisplay}
          show={true}
          onClose={() => {
            setShowResultDisplay(null)
          }}
        />
      )}
      <Dialog
        // fullScreen
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="result-dialog"
      >
        <DialogTitle id="result-dialogitle">Pump Results</DialogTitle>

        {result.length === 0 && (
          <DialogContent>
            <Typography component="p" variant="body1">
              There are no results for these inputs. Try different inputs or
              contact us at support@cedarsolar.com for a custom sizing on large
              projects.
            </Typography>
          </DialogContent>
        )}

        <DialogContent>
          <Typography component="p" variant="body1">
            * Click/Tap on the suggested pumps for more details
          </Typography>
          {sortBy(uniqBy(result, "type"), 'type').map(typeResults => (
            <ResultTable key={typeResults.type}
              title={typeResults.type}
              result={result
                .filter(a => a.type === typeResults.type)}
              selectedResult={row => {
                // prefetchPathname("/pdf/")
                setShowResultDisplay(row)
              }}
            />
          ))}


        </DialogContent>
        <DialogActions>
          {result.length > 0 && !(sizingSaves && sizingSaves.loading) && (
            <Button
              onClick={() => dispatch(addSizingSave())}
              color="primary"
              variant="contained"
            >
              Save for later
            </Button>
          )}
          {sizingSaves && sizingSaves.loading && <CircularProgress />}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      sizingImage: file(relativePath: { eq: "pump-works-2022-no-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.sizingImage.childImageSharp.fluid} />
}

export const CustomerForm = ({ initialValues, saveResult, submitting }) => {
  const { towns, flows } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Formik
      validationSchema={getValidationSchemaPump}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        saveResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({ errors, setFieldTouched, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={matches ? 2 : 0}>
            <Grid item xs={12} sm={6}>
              <Field
                type="string"
                name="customer"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="customer">Customer</InputLabel>
                  <OutlinedInput
                    labelWidth={70}
                    id="customer"
                    placeholder="Customer"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="customer">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                type="string"
                name="phone"
                inputmode="numeric"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="phone">Phone Number</InputLabel>
                  <OutlinedInput
                    labelWidth={110}
                    id="phone"
                    placeholder="+27 081 555 555"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="phone">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="email"
                name="email"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <OutlinedInput
                    labelWidth={110}
                    id="email"
                    placeholder="Email"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="email">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="waterLevel"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="waterLevel">
                    A - Static Water Level (m)
                  </InputLabel>
                  <OutlinedInput
                    labelWidth={190}
                    id="waterLevel"
                    placeholder="20"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="waterLevel">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="pumpHeight"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="pumpHeight">
                    B - Tank Height from Ground Level (m)
                  </InputLabel>
                  <OutlinedInput
                    labelWidth={290}
                    id="pumpHeight"
                    placeholder="5"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="pumpHeight">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="pumpDistance"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="pumpDistance">
                    C - Pump Distance (m)
                  </InputLabel>
                  <OutlinedInput
                    labelWidth={180}
                    id="pumpDistance"
                    placeholder="20"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="pumpDistance">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="pipeDiameter"
              >{({ field }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="pipeDiameter">
                    Pipe Diameter (mm)
                  </InputLabel>

                  <Select
                    labelWidth={150}
                    id="pipeDiameter"
                    type="select"
                    name="pipeDiameter"
                    {...field}
                  >
                    <MenuItem value={25}>25mm</MenuItem>
                    <MenuItem value={32}>32mm</MenuItem>
                    <MenuItem value={40}>40mm</MenuItem>
                    <MenuItem value={50}>50mm</MenuItem>
                    <MenuItem value={63}>63mm</MenuItem>
                    <MenuItem value={70}>70mm</MenuItem>
                    <MenuItem value={80}>80mm</MenuItem>
                    <MenuItem value={100}>100mm</MenuItem>
                  </Select>

                  <ErrorMessage name="pipeDiameter">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="waterNeeded"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="waterNeeded">
                    Water Needed per Day (l)
                  </InputLabel>
                  <OutlinedInput
                    labelWidth={180}
                    id="waterNeeded"
                    placeholder="7000"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="waterNeeded">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              {towns && towns.data && towns.data.length < 2 && (
                <>
                  <span>Connection Error please click below</span>
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(getTowns())
                      dispatch(getFlowsList())
                    }}
                  >
                    Get Towns Again
                  </Button>
                </>
              )}
              {towns && towns.data && towns.data.length > 2 && (
                <Field
                  type="string"
                  name="town"
                >{({ field }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <Autocomplete
                      value={field.value} // !=='unknown'?field.value:towns.data.find(a => 'aqU3HWPX3kRyHJZvQISY' === a.id)}
                      onChange={(event, newValue) => {
                        setFieldTouched()
                        setFieldValue(field.name, newValue)
                      }}
                      options={towns.data}
                      getOptionLabel={
                        option => option.name
                        // ? option.name
                        // : 'apples' //state.townsResult.find(a => option === a.value).name
                      } // option.name?option.name:towns().find(a=>option===a.value)}
                      //   style={{ width: 300 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Closest Town"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "not-town",
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="town">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
                </Field>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="totalWellDepth"
              >{({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="totalWellDepth">
                    D - Total Depth of Borehole (m)
                  </InputLabel>
                  <OutlinedInput
                    labelWidth={220}
                    id="totalWellDepth"
                    placeholder="40"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage name="totalWellDepth">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
            </Grid>
          </Grid>
          {/* {console.log("ferrors", errors)} */}
          {/* {errors && errors.totalWellDepth &&         
          <Typography component="p" variant="body1" style={{color: 'red'}}>
          {errors.totalWellDepth}
        </Typography>} */}
          <br />

          <Button
            color="primary"
            variant="contained"
            disabled={
              isSubmitting ||
              Object.keys(errors).length > 0 ||
              submitting ||
              (towns && towns.data.length < 2) ||
              (flows && flows.data.length < 2) ||
              !towns ||
              !flows
            }
            type="submit"
          >
            Calculate
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const months = [
  { x: "Jan".toString(), y: 0 },
  { x: "Feb".toString(), y: 0 },
  { x: "Mar".toString(), y: 0 },
  { x: "Apr".toString(), y: 0 },
  { x: "May".toString(), y: 0 },
  { x: "Jun".toString(), y: 0 },
  { x: "Jul".toString(), y: 0 },
  { x: "Aug".toString(), y: 0 },
  { x: "Sep".toString(), y: 0 },
  { x: "Oct".toString(), y: 0 },
  { x: "Nov".toString(), y: 0 },
  { x: "Dec".toString(), y: 0 },
]

const generateGraphData = monthFlow => {
  return monthFlow.map((flow, i) => {
    return { month: months[i].x, flow: flow }
  })
}

export function ResultDisplayDialog({ show, result, inputs, hideQuote, onClose }) {

  const { userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(show)
  const [chartImage, setChartImage] = useState(null)
  const [dailyChartImage, setDailyChartImage] = useState(null)

  console.log(inputs, result)

  function blobToDataURL(blob, callback) {
    var a = new FileReader()
    a.onload = function (e) {
      callback(e.target.result)
    }
    a.readAsDataURL(blob)
  }

  function saveMyChart() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("myChart"), {
          bgcolor: "#fff",
          height: 300,
          width: 400,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setChartImage(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)

  }

  function saveDailyChart() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("dailyYieldChart"), {
          bgcolor: "#fff",
          height: 300,
          width: 400,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setDailyChartImage(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)
  }

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <React.Fragment>
      <Dialog
        // fullScreen
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="result-display-dialog"
      >
        <DialogTitle id="result-dialogitle">Sizing Details</DialogTitle>
        <DialogContent>
          <Typography component="h2" variant="h6">
            {result.name}
          </Typography>

          <Typography component="p" variant="body1">
            Flow per day in {result.town}: <span style={{fontWeight: "bold"}}>{result.averageflowPerDay} (liters)</span>
          </Typography>

          <Typography component="p" variant="body1">
            Will the selected pump yield what client wants?:{" "}
            <span style={{fontWeight: "bold"}}>{result.pumpWorks ? "Yes" : "No"}</span>
          </Typography>

          <Typography component="p" variant="body1">
          Pipe friction loss:{" "}
          <span style={{fontWeight: "bold"}}>{result.totalFriction} m</span>
          </Typography>

          <Typography component="p" variant="body1">
          Total Dynamic Head (TDH):{" "}
          <span style={{fontWeight: "bold"}}>{result.totalDynamicHead} m</span>
          </Typography>

          {result.peakFlowAtWell && (
            <Typography component="p" variant="body1">
              Peak flow at the well:{" "}
              <span style={{fontWeight: "bold"}}>{result.peakFlowAtWell} l/h</span>
            </Typography>
          )}

          <Typography component="p" variant="body1">
            Peak Flow at TDH After Losses:{" "}
            <span style={{fontWeight: "bold"}}>{result.peakFlowAtTDHAfterLoss} l/h</span>
          </Typography>
          <br />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <Typography component="h3" variant="body1" style={{fontWeight: "bold"}}>
                Average Water Per Day (liter)
              </Typography>
              <div id="myChart"

                style={{ height: '300px', width: '500px' }}>
                <MyResponsiveBar
                  loaded={() => saveMyChart()}
                  data={
                    generateGraphData(result.monthsFlowsPerDay)
                  } />
              </div>
              <Typography component="h3" variant="body1" style={{fontWeight: "bold"}}>
                Average Water Per Hour (liter)
              </Typography>
              <div style={{ height: '300px', width: '500px' }} id="dailyYieldChart">
                <MyResponsiveLine
                  loaded={() => saveDailyChart()}
                  data={[
                    {
                      id: 'Litres',
                      color: '#43eb34',
                      data: result.averageFlowPerHour.map(
                        (a, i) => ({ x: a.hour, y: a.flow })
                      )
                    },
                  ]} />
              </div>
            </div>
            <div>
              <Typography component="b" variant="h6">Installation Tips</Typography>
              <Typography component="p" variant="body1">
                Latitude: {result.latitude}°. Panel installation between:{" "}
                {result.panelAngle}
              </Typography>
              <Typography component="p" variant="body1">
                Please Note: {result.cableNote}
              </Typography>
              <Typography component="p" variant="body1">
                Recommended pump installation depth is: {result.installDepth}m
              </Typography>
              <Typography component="p" variant="body1">
                Maximum depth under water: {result.maxDepth}m
              </Typography>
              <Typography component="p" variant="body1">
                {result.panelTips}
              </Typography>
              {result.rope && <Typography component="p" variant="body1">
                Recommended {result.rope}
              </Typography>}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {/* {console.log("inputs", inputs)} */}
          {(!chartImage || !dailyChartImage) && <CircularProgress />}
          {chartImage && dailyChartImage && result &&  (
            <BasicPdf fallback={<CircularProgress/>}>
            {({ default: PDF }) => <PDF resultOutput={{
              result,
              userProfile,
              inputs, 
              chartImage, dailyChartImage
            }} />}
        </BasicPdf>
          )}
          {!hideQuote &&
          <Button
            variant="contained"
            disabled={!chartImage && !dailyChartImage}
            onClick={() => {
              const forPdf = {
                result,
                inputs, 
              }
              dispatch(finishSizingResult({data: result}))
              dispatch(addAudit("Pump Sizing Quote", 1, {
                result,
                inputs, 
              }))
              navigate("/app/quote/", {state: {sizing: true, sizingData: forPdf}})
            }}
            color="primary"
          >
            Generate Quote
          </Button>
}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const AppSizingBasic = ({ location }) => {
  const [calculationLoading, setCalculationLoading] = useState(false)
  const {
    towns,
    flows,
    basicPumpCalculation,
    lastBasicPumpInput,
  } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTowns())
    dispatch(getFlowsList())
  }, [])

  return (
    <div>
      <Typography component="h1" variant="h4">
        Pump sizing
      </Typography>
      <Typography component="p" variant="body1">
        Our sizing works out the Total Dynamic Head taking pipe friction loss
        into consideration. Local weather data is used to accurately predict how
        a pump will perform in your chosen area. The best pump options with the
        average daily yield is returned to you.
      </Typography>
      <div
        style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Image />
      </div>
      {(!towns || !towns.data || !flows || !flows.data) && (
        <>
          <CircularProgress />
          <span style={{ marginLeft: "20px" }}>Loading tool...</span>
        </>
      )}
      {towns && towns.data && flows && flows.data && (
        <>
          <CustomerForm
            isSubmitting={calculationLoading}
            initialValues={{
              customer:
                lastBasicPumpInput && lastBasicPumpInput.customer
                  ? lastBasicPumpInput.customer
                  : "",
              phone:
                lastBasicPumpInput && lastBasicPumpInput.phone
                  ? lastBasicPumpInput.phone
                  : "",
              email:
                lastBasicPumpInput && lastBasicPumpInput.email
                  ? lastBasicPumpInput.email
                  : "",
              waterLevel:
                lastBasicPumpInput && lastBasicPumpInput.waterLevel
                  ? lastBasicPumpInput.waterLevel
                  : 20,
              pumpDistance:
                lastBasicPumpInput && lastBasicPumpInput.pumpDistance
                  ? lastBasicPumpInput.pumpDistance
                  : 10,
              pumpHeight:
                lastBasicPumpInput && lastBasicPumpInput.pumpHeight
                  ? lastBasicPumpInput.pumpHeight
                  : 5,
              waterNeeded:
                lastBasicPumpInput && lastBasicPumpInput.waterNeeded
                  ? lastBasicPumpInput.waterNeeded
                  : 5000,
              pipeDiameter:
                lastBasicPumpInput && lastBasicPumpInput.pipeDiameter
                  ? lastBasicPumpInput.pipeDiameter
                  : 32,
              town:
                lastBasicPumpInput && lastBasicPumpInput.town
                  ? lastBasicPumpInput.town
                  : towns.data.find(a => a.name === "Unknown"),
              totalWellDepth:
                lastBasicPumpInput && lastBasicPumpInput.totalWellDepth
                  ? lastBasicPumpInput.totalWellDepth
                  : 50,
            }}
            saveResult={values => {
              setCalculationLoading(true)
              setTimeout(() => {
                dispatch(pumpCalculation(values, "Pumps", flows.data))
                // doCalculation(values)
                // saveCalculateAudit()
                setCalculationLoading(false)
                // setCalculationValues(values)
                // setShowSaved(null)
              }, 300)
            }}
          />
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/app/sizing")}
          >
            Back to sizing tools
          </Button>
        </>
      )}

      {basicPumpCalculation && basicPumpCalculation.data && (
        <ResultDialog
          result={basicPumpCalculation.data}
          show={true}
          onClose={() => {
            dispatch(
              finishBasicPumpCalculation({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
          }}
        />
      )}
    </div>
  )
}

export default AppSizingBasic
